import styled from "styled-components"

import { Link } from "./links"

import { colors } from "../../utils/tokens"

export const InlineLink = styled(Link)`
  color: ${colors.sand};
  display: inline;
  border-bottom: 1px solid;
  border-bottom-color: currentColor;

  transition: color 0.4s;

  &:hover,
  &:focus {
    color: ${colors.space};
  }
`
