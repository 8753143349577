import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroSection, LargeSection } from "../components/containers"
import { H1, H2, H4, P } from "../components/typography"
import { FlDivider, InsDivider } from "../components/dividers"
import { InlineLink } from "../components/links/inlineLink"

const TermsOfUsePage = () => (
  <Layout>
    <SEO
      title="Terms of Use"
      description="Learn more about your usage rights and obligations of using the site for your personal information, educational, and entertainment purposes. Concrezan is an independent brand experience and service design consultancy."
    />
    <HeroSection>
      <H4>Effective Date: December 23, 2019</H4>
      <H1>Terms of Use</H1>
      <P>
        Please read these Terms of Use carefully as they contain important
        information about your legal rights, remedies, and obligations. By
        accessing or using the Site, you agree to comply with and be bound by
        these Terms of Service.
      </P>
    </HeroSection>
    <FlDivider />
    <LargeSection>
      <P>
        These Terms of Service (“Terms”) constitute a legally binding agreement
        (“Agreement”) between you and Concrezan (as defined below) governing
        your access to and use of the Concrezan website, including any subdomain
        thereof, and any other websites through which Concrezan makes it
        services available (collectively, “Site”).
      </P>
      <P>
        When these Terms mention “Concrezan”, “service provider”, “we”, “us”, or
        “our”, it refers to Concrezan Studio.
      </P>
    </LargeSection>
    <FlDivider />
    <LargeSection>
      <H2>Privacy Policy</H2>
      <P>
        Concrezan respects the privacy of visitors and users of this Site. Our
        collection and use of personal information, when it is voluntarily
        submitted to us in connection with your access to and use of the Site
        are described in our{" "}
        <InlineLink to="/privacy-policy">Privacy Policy</InlineLink>.
      </P>
    </LargeSection>
    <InsDivider />
    <LargeSection>
      <H2>The Scope of the Site</H2>
      <P>
        The information contained in this Site is intended for general
        information purposes only. Concrezan has made all reasonable efforts to
        ensure that the information on this Site is accurate at the time of
        inclusion. However, there may be inaccuracies and occasional errors for
        which Concrezan apologizes.
      </P>
      <P>
        Concrezan makes no representations or warranties about the information
        provided throughout this Site, including any hypertext links to any
        website or other items used either directly or indirectly from this
        Site. Concrezan accepts no liability for any inaccuracies or omissions
        on this Site. Any decisions based on information contained in
        Concrezan’s Site are the sole responsibility of the visitor.
      </P>
    </LargeSection>
    <InsDivider />
    <LargeSection>
      <H2>Content and Copyright</H2>
      <P>
        All works contained on this Site or parts thereof, including texts,
        files, compositions, and images, are protected by design law and/or
        copyright. They may not be published, reproduced, distributed or
        otherwise used in whole or in part without the prior written permission
        of Concrezan. We permit you to make copies of this Site as necessary
        incidental acts during your viewing of it; you may take a print for your
        personal use of so much of the Site as is reasonable for private
        purposes. You may not frame this Site nor link to a page other than the
        homepage without our express permission in writing.
      </P>
      <P>
        Where the content on this Site was not created by the operator, the
        copyrights of third parties are respected. In particular, the contents
        of third parties are marked as such. Should you nevertheless become
        aware of copyright infringement, please inform us of this. As soon as we
        become aware of any infringements, we will remove such content
        immediately.
      </P>
    </LargeSection>
    <InsDivider />
    <LargeSection>
      <H2>Disclaimers</H2>
      <P>
        As a service provider, we are responsible for our own content on these
        pages in accordance with the general laws. As a service provider,
        however, we are not obliged to monitor transmitted or stored third-party
        information or to investigate circumstances that indicate illegal
        activity. You agree not to send any unsolicited promotional or
        advertising material, spam, or similar materials, or any volume messages
        that may interfere with the operation of this Site or with the enjoyment
        of this website by other visitors.
      </P>
      <P>
        Obligations to remove or block the use of information in accordance with
        general laws remain unaffected by this. Any liability concerning this
        matter can only be assumed from the point in time at which the
        infringement becomes known to us. As soon as we become aware of such
        infringements, we will remove the content immediately.
      </P>
    </LargeSection>
    <InsDivider />
    <LargeSection>
      <H2>Links</H2>
      <P>
        The Site may contain links to third-party websites or resources
        (“Third-Party Services”). Such Third-Party Services may be subjected to
        different terms and conditions and privacy practices. As the content of
        these websites is not under our control, we cannot assume any liability
        for such external content.
      </P>
      <P>
        Concrezan is not responsible for the availability or accuracy of such
        Third-Party Services, or the content, products, or services available
        from such Third-Party Services. The respective provider or operator of
        the pages is always responsible for the contents of the linked pages. A
        permanent control of the contents of the linked pages is not reasonable
        without concrete evidence of an infringement. As soon as we become aware
        of any legal infringements, we will remove such links immediately.
      </P>
    </LargeSection>
    <InsDivider />
    <LargeSection>
      <H2>Modification of these Terms</H2>
      <P>
        Concrezan reserves the right to modify these Terms at any time per this
        provision. If we make changes to these Terms, we will post the revised
        Terms on the Site and update the “Last Updated” date at the top of these
        Terms. By your continued access to or use of the Site will constitute
        acceptance of the revised Terms.
      </P>
    </LargeSection>
    <InsDivider />
    <LargeSection>
      <H2>Governing Law</H2>
      <P>
        Your use of this website and downloads from it and the operation of
        these terms shall be governed in accordance with the laws of Malaysia.
        The courts in Malaysia shall have exclusive jurisdiction over any
        dispute arising out of your use of this website.
      </P>
    </LargeSection>
    <InsDivider />
    <LargeSection>
      <P>
        In the event that any or any part of the terms contained in these terms
        and conditions shall be determined by any competent authority to be
        invalid, unlawful, or unenforceable to any extent, such term shall to
        that extent be severed from the remaining terms which shall continue to
        be valid and enforceable to the fullest extent permitted by law.
      </P>
    </LargeSection>
  </Layout>
)

export default TermsOfUsePage
